import { initCommandBar } from '@/utils/client/command_bar'
import { loadTranslations, setLocale } from '@/utils/shared/intl'
import type { App } from 'vue'

export default (app: App) => {
  if (globalThis?.window) {
    // For rehydration. With SSR, the locale data is set in Layout.astro
    setLocale(window.$currentLocale)
    loadTranslations(window.$currentLocale)
    void initCommandBar()
  }

  return app
}
